<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="desserts"
      sort-by="calories"
      class="ma-2 elevation-1"
    >
      <template v-slot:item.icon="{ item }">
        <img :src="item.icon" width="50" height="50" />
      </template>
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>ВИДЫ НАСАЖДЕНИЙ</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="500px">
            <v-card>
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-text-field
                    v-model="editedItem.name"
                    label="Название"
                  ></v-text-field>
                  <v-select
                    :items="geomOptions"
                    item-text="name"
                    item-value="value"
                    v-model="editedItem.geometry_type"
                    label="Тип геометрии"
                  />
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">Отмена</v-btn>
                <v-btn color="blue darken-1" text @click="save"
                  >Сохранить</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)">
          mdi-pencil
        </v-icon>
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="initialize">Обновить</v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import tree from "@/assets/tree_green.png";
import bush from "@/assets/bush_green.png";
import curtain from "@/assets/curtain_green.png";
import hedge from "@/assets/hedge.png";
import flower_garden from "@/assets/flower_garden.png";
import lawn from "@/assets/lawn.png";
import vine from "@/assets/vine_green.png";

export default {
  name: "TypesTab",

  data: () => ({
    inputName: "",
    dialog: false,
    headers: [
      {
        text: "Название",
        align: "start",
        value: "name"
      },
      {
        text: "Иконки",
        align: "start",
        value: "icon"
      },
      {
        text: "Тип геометрии",
        align: "start",
        value: "geometry_typeRu"
      },
      { text: "Действия", value: "actions", sortable: false, width: 100 }
    ],
    editedIndex: -1,
    editedItem: {
      name: "",
      geometry_type: ""
    },
    defaultItem: {
      name: "",
      geometry_type: ""
    },
    itemToDelete: null,
    geomOptions: [
      { value: "Point", name: "Точка" },
      { value: "LineString", name: "Линия" },
      { value: "Polygon", name: "Многоугольник" }
    ]
  }),

  computed: {
    ...mapGetters(["allTypesData"]),
    formTitle() {
      return this.editedIndex === -1 ? "Новый" : "Редактировать";
    },
    desserts() {
      let arr = [];
      for (let t of this.allTypesData) {
        let el = {};
        if (t.id === 1) {
          el.geometry_typeRu = "Точка";
          el.icon = tree;
        }
        if (t.id === 2) {
          el.geometry_typeRu = "Точка";
          el.icon = bush;
        }
        if (t.id === 3) {
          el.geometry_typeRu = "Многоугольник";
          el.icon = curtain;
        }
        if (t.id === 4) {
          el.geometry_typeRu = "Многоугольник";
          el.icon = curtain;
        }
        if (t.id === 5) {
          el.geometry_typeRu = "Линия";
          el.icon = hedge;
        }
        if (t.id === 6) {
          el.geometry_typeRu = "Многоугольник";
          el.icon = flower_garden;
        }
        if (t.id === 7) {
          el.geometry_typeRu = "Многоугольник";
          el.icon = lawn;
        }
        if (t.id === 8) {
          el.geometry_typeRu = "Многоугольник";
          el.icon = vine;
        }
        el.id = t.id;
        el.name = t.name;
        el.geometry_type = t.geometry_type;
        arr.push(el);
      }
      return arr;
    }
  },

  watch: {
    dialog(val) {
      val || this.close();
    }
  },

  methods: {
    ...mapActions([
      "getTypesData",
      "destroyTypeData",
      "createTypeData",
      "pushTypeData"
    ]),
    initialize() {
      this.getTypesData();
    },
    save() {
      if (this.editedIndex > -1) {
        this.pushTypeData({
          id: this.editedItem.id,
          name: this.editedItem.name,
          geometry_type: this.editedItem.geometry_type
        });
      } else {
        this.createTypeData({
          name: this.editedItem.name,
          geometry_type: this.editedItem.geometry_type
        });
      }
      this.close();
    },
    editItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    }
  }
};
</script>

<style scoped></style>
